import Link from 'next/link'

const footerItems = {
  main: [
    { name: 'Docs', href: 'https://swift.cloud/docs' },
    { name: 'Blog', href: '/blog' },
    { name: 'Sign In', href: 'https://console.swift.cloud/login' },
    { name: 'Terms of Service', href: '/legal/terms' },
    { name: 'Privacy Policy', href: '/legal/privacy' }
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/SwiftCloudApp',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
        </svg>
      )
    },
    {
      name: 'GitHub',
      href: 'https://github.com/swift-cloud',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
            clipRule='evenodd'
          />
        </svg>
      )
    }
  ]
}

function Footer() {
  return (
    <>
      <div className='bg-white'>
        <div className='max-w-7xl sm:px-6 lg:px-8 px-4 py-16 mx-auto'>
          <div className='lg:grid lg:grid-cols-2 lg:gap-4 bg-dots overflow-hidden rounded-lg shadow-xl'>
            <div className='sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20 px-6 pt-10 pb-12'>
              <div className='lg:self-center'>
                <h2 className='sm:text-4xl text-3xl font-extrabold text-orange-100'>
                  <span className='block'>Ready to get started?</span>
                  <span className='block'>Request early access.</span>
                </h2>
                <p className='mt-4 text-lg leading-6 text-white'>
                  Join the beta and enjoy 1 click deploys, HTTP/3, globally distributed, 100ms cold
                  starts, custom domains and more...
                </p>
                <a
                  href='https://console.swift.cloud/register'
                  className='mt-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 inline-block px-6 py-3 text-lg font-medium text-white bg-gradient-to-l from-[#FE792D] via-[#FE6839] to-[#FF504D] border border-transparent rounded-full shadow-sm'
                >
                  Get Early Access
                </a>
              </div>
            </div>
            <div className='aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1 -mt-6'>
              <img
                className='sm:translate-x-16 lg:translate-y-20 object-cover object-left-top transform translate-x-6 translate-y-6 rounded-md'
                src='/new-project.jpg'
                alt='App screenshot'
              />
            </div>
          </div>
        </div>
      </div>
      <footer className='bg-white'>
        <div className='max-w-7xl sm:px-6 lg:px-8 px-4 py-12 mx-auto overflow-hidden'>
          <nav className='flex flex-wrap justify-center -mx-5 -my-2' aria-label='Footer'>
            {footerItems.main.map((item) => (
              <div key={item.name} className='px-5 py-2'>
                <Link href={item.href} legacyBehavior>
                  <a className='text-slate-700 text-base'>{item.name}</a>
                </Link>
              </div>
            ))}
          </nav>
          <div className='flex justify-center mt-8 space-x-6'>
            {footerItems.social.map((item) => (
              <a key={item.name} href={item.href} className='text-slate-600'>
                <span className='sr-only'>{item.name}</span>
                <item.icon className='w-6 h-6' aria-hidden='true' />
              </a>
            ))}
          </div>
          <p className='text-slate-600 mt-8 text-center'>
            &copy; {new Date().getFullYear()} Swift Cloud, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
